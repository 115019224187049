import { ReactElement } from 'react';
import {
    BadgePercentLightIcon,
    BallotCheckLightIcon,
    CalendarDayLightIcon,
    CameraHomeLightIcon,
    CoinsLightIcon,
    CommentsAltLightIcon,
    EnvelopeLightIcon,
    FileCertificateLightIcon,
    FileImportLightIcon,
    GiftCardLightIcon,
    HandHoldingUsdLightIcon,
    HandshakeAltLightIcon,
    InboxLightIcon,
    LaptopCodeLightIcon,
    LaptopHouseLightIcon,
    MobileAndroidLightIcon,
    MoneyCheckEditAltLightIcon,
    PlayCircleLightIcon,
    RepeatLightIcon,
    StarLightIcon,
    TagsLightIcon,
    TasksLightIcon,
    UserCircleLightIcon,
    UserLockLightIcon,
    UsersClassLightIcon,
    UsersLightIcon,
    VideoLightIcon,
    WindowLightIcon,
} from '../Icons';

export type FeatureItem = {
    icon: ReactElement;
    label: string;
    description: string;
    badge?: string;
};

export const listOfFeatures: Array<FeatureItem> = [
    {
        label: 'In-person Courses',
        icon: <UsersClassLightIcon />,
        description:
            'Create courses and workshops that are taught in-person. If you provide location details for your course, we will show them on a map on the course page 🗺. When someone books, we will direct them to the relevant location in confirmation & reminder emails, as well as from their individual booking page 📍.',
    },
    {
        label: 'Online Live Courses',
        icon: <VideoLightIcon />,
        description:
            'Sell workshops and events that are delivered through 🎦 Zoom/Meet/Teams or any other service. You can include dedicated joining instructions for your sessions which will be emailed to the customer after purchase 📧📲',
    },
    {
        label: 'Online Course Builder & LMS',
        icon: <PlayCircleLightIcon />,
        description:
            'Upload videos, articles and other content into Cademy and run on-demand self-taught courses with ease. After purchasing a course, the customer will get a secure magic link that will allow them to access the course effortlessly without a password. At the same time, they will be prevented from being able to share access to the course with others 🔐 💫',
    },
    {
        label: 'Blended Courses',
        icon: <LaptopHouseLightIcon />,
        description:
            '🔀 Combine online sessions, in-person workshops and even on-demand content to be consumed by the student individually with a blended course.',
    },
    {
        label: 'On-request Courses',
        icon: <HandshakeAltLightIcon />,
        description:
            'If the dates, location and delivery methods are flexible and agreed with each client individually, then arranging your course on-request is the perfect solution for your needs. The customer will be able to place an enquiry, and you can negotiate all the details directly before sending them a payment link to complete the booking 👋 🤝 💰',
    },
    {
        label: 'Calendar Scheduling',
        icon: <CalendarDayLightIcon width={14} />,
        description:
            'Sync your Google or Outlook Calendar with Cademy and offer booking options based on your real-time availability. This is perfect for tutors, trainers, coaches, consultants, corporate trainers and anyone operating on a flexible schedule.',
        badge: 'New',
    },
    {
        label: 'Reviews',
        icon: <StarLightIcon />,
        description:
            'Automatically capture reviews and feedback from your customers and show them on your course and profile pages.',
        badge: 'New',
    },
    {
        label: 'Quizzes & Assessments',
        icon: <BallotCheckLightIcon />,
        description:
            'Create quizzes and assessments directly within your courses, and mark submissions automatically or manually.',
    },
    {
        label: 'Payment Requests',
        icon: <HandHoldingUsdLightIcon />,
        description:
            'Manually add orders into your system to keep track of all your contacts and sales in a single place. You can also request payment for any manually created booking, and the customer will be emailed a secure link where they will be able to pay the agreed amount to complete the booking  🤲 💰',
    },
    {
        label: 'Certificates',
        icon: <FileCertificateLightIcon />,
        description:
            'You can automatically issue attendance certificates to attendees directly in Cademy. This can be done automatically without you ever lifting a finger 🤓',
    },
    {
        label: 'Attendance & Progress Tracking',
        icon: <TasksLightIcon />,
        description:
            'Capture contact details for all attendees, not just the person booking, and easily keep track of who attended which date with our simple attendance tracking features ✅',
    },
    {
        label: 'Embeddable Booking System',
        icon: <LaptopCodeLightIcon />,
        description:
            "📲 Embed our booking & checkout system on your website and take orders and payments without redirecting customers externally. Improve the user experience and conversion rate of your website's visitors with a fast, secure and intuitive course browsing and purchasing interface. Our system works with Wordpress, Wix, Squarespace or any other website and requires no technical knowledge to implement. What's more, our friendly team is very happy to help you with the setup.",
    },
    {
        label: 'Branded Minisite',
        icon: <WindowLightIcon />,
        description:
            'Showcase your courses, memberships and more through your branded landing page. Customers can easily browse and place new orders or manage existing ones by logging in through a secure passwordless sign in.',
    },
    {
        label: 'Automatic & Bulk Emailing',
        icon: <EnvelopeLightIcon />,
        description:
            'Edit and personalise emails sent to customers after they place an order with you 📧. Easily filter and select contacts, then bulk email them with a personalised template. Remind folks to bring things, warn about changes or promote upcoming sessions - in as little as 3 clicks 📢',
    },
    {
        label: 'Universal Inbox',
        icon: <InboxLightIcon />,
        description:
            'Handle all questions from current and perspective customers in a single inbox. No more context jumping from your email app to your booking system to answer questions, create bookings, update orders, etc. Easily see, assign, respond and handle your messages directly in Cademy.',
    },
    {
        label: 'Contacts',
        icon: <UsersLightIcon />,
        description:
            'Keep track of all your contacts in a single place. Easily search and filter to find the right people and control which contact details or store about your contacts in your system 👥',
    },
    {
        label: 'Notes & Tags',
        icon: <TagsLightIcon />,
        description:
            'Retire your post-its and diaries, and start adding notes and tags on contacts and orders to keep track of internal jottings in a structured and easy to find way 📝 ',
    },
    {
        label: 'Keep Track of All Interactions',
        icon: <CommentsAltLightIcon />,
        description:
            'See every booking, order, enquiry, membership charge, note or email sent to your contacts to get a full picture of all your interactions with them. No more wondering who someone is.',
    },
    {
        label: 'Memberships & Subscriptions',
        icon: <RepeatLightIcon />,
        description:
            '🔁 Create memberships and automatically charge customers as a recurring subscription easier than ever before. Set up multiple memberships and control the benefits of each plan. Set up courses that are available to members only, or offer different discounts for your services across your plans.',
        badge: 'New',
    },
    {
        label: 'Stripe, PayPal & Klarna',
        icon: <CoinsLightIcon />,
        description:
            'We partnered with Stripe, PayPal and Klarna to offer you simple and secure options to take payments from your customers. This means that payments made through our tools get routed to your bank accounts quickly and safely. No extra fees from Cademy, no small-print, no nonsense.',
    },
    {
        label: 'Invoices, Bank Payments & VAT Handling',
        icon: <MoneyCheckEditAltLightIcon />,
        description:
            'Offer your customers the ability to book and pay by cash, bank transfer or invoice. A PDF invoice will automatically be emailed to your customer and keeping track of paid/unpaid bookings is easier than ever. You can also add your VAT details and show VAT pricing on every receipt.',
        badge: 'New',
    },
    {
        label: 'Zoom Integration',
        icon: <CameraHomeLightIcon />,
        description:
            "Create 🎦 Zoom links for your classes with a click, thanks to our Zoom integration. Joining instructions will automatically be included on the student's booking page and in all confirmation & reminder emails.",
    },

    {
        label: 'Offers & Addons',
        icon: <BadgePercentLightIcon />,
        description:
            'Offer different pricing options on your checkout and sell additional services or products with flexible offers and add-ons. Reward your loyal customers with discounts or offer your followers an incentive to act with limited time offers and promo codes.',
    },
    {
        label: 'Gift Cards',
        icon: <GiftCardLightIcon />,
        description:
            'Sell gift vouchers through Cademy, and the lucky recipients will be able to redeem them when booking any of your courses 💝',
    },
    {
        label: 'Secure Customer Profile',
        icon: <UserCircleLightIcon />,
        description:
            'Thanks to our simple & secure passwordless log-in flow, your customers can sign into their own private profile to view all their current and past orders, manage memberships and update their contact details.',
    },
    {
        label: 'Multiple Users',
        icon: <UserLockLightIcon />,
        description:
            'Invite more users and teammates to your Cademy instance and collaborate on managing your courses and bookings from a single place.',
    },
    {
        label: 'Imports & Exports',
        icon: <FileImportLightIcon />,
        description:
            'Easily export your contacts & bookings to a spreadsheet or import your contacts into Cademy in just a few minutes with our importing feature.',
    },
];

export default listOfFeatures;
